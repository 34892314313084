import React, { useState } from 'react';
import 'assets/styles/pages/sidebar.scss';
import 'assets/styles/pages/dashboardstatus.scss';
import Logo from 'assets/images/logo/forest-logo.png';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'gatsby';
import adminprofileicon from 'assets/images/admin/admin-user-icon.png';
import bellicon from 'assets/images/admin/bell-icon.png';
import logouticon from 'assets/images/admin/logout-icon.png';
import childinitiative from 'assets/images/admin/childinitiative.png';
import smalltree from 'assets/images/admin/small-tree.png';
import eyeicon from 'assets/images/admin/eye-icon.svg';
import projecticon from 'assets/images/admin/projecticon.svg';
import plusicon from 'assets/images/admin/plus-icon.svg';
//import dashboardicon from 'assets/images/admin/dashboard-icon.svg';
import deleteicon from 'assets/images/admin/delete-icon.svg';
import editicon from 'assets/images/admin/edit-icon.svg';
import planterthumb from 'assets/images/admin/planter-thumb-icon.png';
import dashboardgreen from 'assets/images/admin/dashboard-green.png';
import treeiconblack from 'assets/images/admin/tree-icon-black.png';
import Viewthubnailslider from 'components/register-plantation/viewthumbnail-slider';
import dashboardicon from 'assets/images/admin/dashboard-icon.svg';
import speciesgreen from 'assets/images/admin/species-green-icon.png';
import donorgrey from 'assets/images/admin/donorgrey.png';
import donatedtree from 'assets/images/admin/donated-tree.svg';
import speciestreeview from 'assets/images/admin/species-view-tree.png';
import plannedby from 'assets/images/icons/planned-by.png';
import speciesgreendonated from 'assets/images/admin/species-green.svg';
import speciescount from 'assets/images/admin/species-count.svg';
import mappingstatus from 'assets/images/admin/mapping-greenicon.svg';
import mulspeciestree from 'assets/images/register-plantation/multiple-species-tree.png';
//import sidebar css from react-pro-sidebar module and our custom css
import 'react-pro-sidebar/dist/css/styles.css';
import 'assets/styles/pages/customheader.scss';

export default function Donorsviewpage() {
  return (
    <>
      <div className='dashboard-status-wrapper'>
        <div className='row'>
          <div className='col-md-12'>
            <h3 className='dark-theme-green recently-text'> Back </h3>
          </div>
        </div>

        <div className='mg-top20'>
          <div className='white-iitiative-wrapper'>
            <div className='display-item'>
              <h3 className='title-item'> ROSY DOE </h3>
            </div>

            <div className='display-date-time'>
              <p> Tue, Apr 20, 2021, 12.45PM </p>
            </div>

            <div className='dis-in-planed'>
              <span className='dis-image-item'>
                <img src={speciesgreendonated} alt='treeRightIconGreen' />
              </span>

              <h5 className='planed-text'>Transaction ID: </h5>
              <h5 className='date-calc-text'> #101975841320</h5>
            </div>

            <div className='dis-in-planed'>
              <span className='dis-image-item'>
                <img src={speciesgreendonated} alt='treeRightIconGreen' />
              </span>

              <h5 className='planed-text'>Donated On:</h5>
              <h5 className='date-calc-text'> #101975841320</h5>
            </div>

            <div className='dis-in-planed'>
              <span className='dis-image-item'>
                <img src={speciescount} alt='treeRightIconGreen' />
              </span>

              <h5 className='planed-text'>Species Count:</h5>
              <h5 className='date-calc-text'> 10</h5>
            </div>

            <div className='dis-in-planed'>
              <span className='dis-image-item'>
                <img src={mappingstatus} alt='treeRightIconGreen' />
              </span>

              <h5 className='planed-text'>Mapping Status :</h5>
              <h5 className='date-calc-text'> Mapped</h5>
            </div>

            <hr />

            <h4> Donation Details </h4>
            <div className='row'>
              <div className='col-md-12 multiple-species-box-wrapper'>
                <div className='dis-flex icon-species-text'>
                  <span className='mulspecies-tree'>
                    <img src={speciesgreendonated} alt='treeRightIconGreen' />
                  </span>

                  <div>
                    <h5 className='mul-title'> Project name </h5>
                    <p> Green Kanha Initiative </p>
                  </div>
                </div>
                <div className='list-species-item'>
                  <div className='wid-50species'>
                    <h4 className='surving-item'> Mobile number </h4>
                    <h4 className='surving-number-item'> + 91 9874561230</h4>
                    <h4 className='surving-item'> Email ID </h4>
                    <h4 className='surving-number-item'> rosy.doe@gmail.com</h4>
                    <h4 className='surving-item'> Amount Paid </h4>
                    <h4 className='surving-number-item'> 1490</h4>
                  </div>
                  <div className='wid-50species'>
                    <h4 className='surving-item'> Height </h4>
                    <h4 className='surving-number-item'> 153.2cm </h4>
                    <h4 className='surving-item'> Girth(DBH) </h4>
                    <h4 className='surving-number-item'> 1.2cm </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-right mg-top20 display-button-order'>
              {/* <button className='btn btn-approve save-width posi-close-top'> Ok </button> */}
              <Link className='btn btn-approve save-width posi-close-top' to='/admin/donorlist'>
                {' '}
                Species{' '}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
