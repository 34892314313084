import React from 'react';

import AdminLayout from '../../adminlayout/index';

import SEO from 'components/seo/index';

import Donorsviewpage from 'components/admin/donorsview';

const Donorsview = () => {
  return (
    <>
      <SEO title='Forests By Heartfulness admin' />
      <AdminLayout>
        <div>
          <Donorsviewpage />
        </div>
      </AdminLayout>
    </>
  );
};

export default Donorsview;
